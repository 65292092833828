<div [hidden]="!popupVideoPlay" class="popup-play-container">
<iframe [src]=" popupVideoURL | embedding" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowfullscreen="true" class="video" frameborder="0"></iframe>
<div (click)="playPopupVideo()" class="close-container close-btn-border">
<div class="leftright"></div>
<div class="rightleft"></div>
</div>
</div>
<div class="container">
<div [ngClass]="{&quot;fade-out&quot;: popupStoryPlay}" class="header">
<!-- /Logo -->
<img class="logo unselectable" src="{{logo}}">
<!-- /Title -->
<div class="subtitle">
<span class="unselectable bold-text">Info Hub</span>
</div>
<div class="mobile-contact">
<i (click)="contactCtrl()" class="fas fa-envelope mobile-filter-btn"></i>
</div>
<!-- /Desktop Filters -->
<div [ngClass]="{&#39;active-mobile-filter&#39;: mobileMenuDisplay}" class="filter mobile-filter">
<div (click)="contactCtrl()" class="filter-contact">
<span class="unselectable">CONTACT US</span>
</div>
</div>
</div>
<!-- /Landing page Map -->
<div class="map-container">
<div [ngStyle]="{&#39;background-image&#39;: &#39;url(&#39; + mapBg + &#39;)&#39;}" class="map">
<!-- %iframe.map-vid-container{:allow => "autoplay; fullscreen", :allowfullscreen => "", :frameborder => "0", '[src]' => " mapVid | embedding"} -->
<div class="map-vid-container" id="map-vid"></div>
<!-- /Hot spots -->
<div [ngClass]="{&quot;fade-out&quot;: popupStoryPlay}" class="hotspot-container">
<div class="popout-video">
<img (click)="playPopupVideo()" [ngClass]="{&quot;fade-out&quot;: popupStoryPlay}" src="assets/images/team_royal_mail.png">
<!-- .filter-contact -->
<!-- %i.fas.fa-play-circle -->
<!-- %span.unselectable PLAY -->
</div>
<app-hotspot (click)="boxDisplayCtrl(pin)" *ngFor="let pin of hotspots" [dataIn]="pin" [hidden]="hotspotDisplay(pin)" [ngStyle]="{&quot;left&quot;: pin.pinPositionX + &quot;%&quot;, &quot;top&quot;: pin.pinPositionY + &quot;%&quot;}" class="hotspot-style"></app-hotspot>
<app-hotspot (click)="playStory()" [dataIn]="popupStory" [ngStyle]="{&quot;left&quot;: popupStory.pinPositionX + &quot;%&quot;, &quot;top&quot;: popupStory.pinPositionY + &quot;%&quot;}" class="hotspot-style"></app-hotspot>
<!-- /%app-hotspot.hotspot-style{'[ngStyle]' => '{"left": popupVideo.pinPositionX + "%", "top": popupVideo.pinPositionY + "%"}', '[dataIn]' => 'popupVideo', '(click)' => "playPopupVideo()"} -->
</div>
<div [ngClass]="{&quot;fade-in&quot;: popupStoryPlay}" class="story-container">
<!-- %iframe.map-vid-container.video{:allow => "autoplay; gyroscope; fullscreen", :allowfullscreen => "", :frameborder => "0", '[src]' => " popupStoryURL | embedding"} -->
<div class="map-vid-container" id="popup-vid"></div>
<div (click)="playStory()" class="close-container close-btn-border">
<div class="leftright"></div>
<div class="rightleft"></div>
</div>
</div>
</div>
</div>
<!-- /Into Box -->
<div [hidden]="!boxDisplay" class="info-container-filter"></div>
<div [hidden]="!boxLoaderDisplay" class="info-loader">
<div class="box-loader"></div>
</div>
<div [hidden]="!boxDisplay" [ngClass]="{&#39;box-content-display&#39;: boxContentDisplay}" class="info-container">
<div class="inner">
<div (click)="boxDisplayCtrl(null)" [hidden]="boxDataDisplay == &#39;contact&#39; &amp;&amp; boxData" class="close-container">
<div class="leftright"></div>
<div class="rightleft"></div>
</div>
<div (click)="boxContentDisplayCtrl(&quot;intro&quot;)" [hidden]="boxDataDisplay !== &#39;contact&#39; || !boxData" class="back-container">
<i class="fas fa-chevron-left"></i>
<span>BACK</span>
</div>
<!-- /Into Box Logo -->
<div class="inner-header">
<span>
<div [ngStyle]="{&#39;background-image&#39;: &#39;url(&quot;assets/images/Royal_Mail Logo.png&quot;)&#39;}" class="inner-logo"></div>
</span>
<!-- /Normal Title -->
<h1 [hidden]="boxDataDisplay == &#39;contact&#39;">{{boxDataTitle}}</h1>
<!-- /Contact Form Title -->
<h1 [hidden]="boxDataDisplay !== &#39;contact&#39;">Contact Us</h1>
</div>
<!-- /Chrevrons turn the slideshow -->
<div class="image-container-feature">
<!-- /{'[ngClass]' => "{'display-text-box': boxDataDisplay == 'intro'}"} -->
<!-- /%i.fas.fa-chevron-left.prev{'(click)' => 'turnSlideshow(-1)', '[ngClass]' => "{'display-chevrons': boxDataDisplay != 'intro'}", '[hidden]' => "slideshowLength === 0"} -->
<!-- /%i.fas.fa-chevron-right.next{'(click)' => 'turnSlideshow(1)', '[ngClass]' => "{'display-chevrons': boxDataDisplay != 'intro'}", '[hidden]' => "slideshowLength === 0"} -->
<!-- /Video -->
<div [hidden]="boxDataDisplay != &#39;video&#39;" class="text-box central-text-box">
<!-- /Video Player -->
<iframe [hidden]="!boxDataVideoURL" [src]=" boxDataVideoURL | embedding" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" class="video-container" frameborder="0"></iframe>
<span [hidden]="boxDataVideoURL" class="coming-soon-text">Video coming soon.</span>
<div (click)="playVideo(video[&#39;videoURL&#39;])" *ngFor="let video of boxDataVideo" class="other-video-container">
<i [ngClass]="{&#39;video-playing&#39;: boxDataVideoURL == video[&#39;videoURL&#39;]}" class="fas fa-play play-btn"></i>
<span class="closed-video">{{video['title']}}</span>
</div>
</div>
<div [hidden]="boxDataDisplay != &#39;contact&#39;" class="text-box contact-textbox">
<!-- /Contact Form -->
<div [hidden]="GDPRdisplayStatus" class="contact-container">
<div class="contact-cell">
<label for="contactFullName">What's your name?</label>
<input [(ngModel)]="contactFullName" class="contact-form" id="contactFullName" type="text">
</div>
<div class="contact-cell">
<label for="job">Which function do you work in?</label>
<select [(ngModel)]="contactJob" class="contact-form" id="job">
<option *ngFor="let job of boxDataJobs">{{job['jobTitle']}}</option>
</select>
</div>
<div class="contact-cell">
<!-- /%input.contact-form{type:"text", "[(ngModel)]" => ""} -->
</div>
<div class="contact-cell">
<label for="contactMessage">Please drop your question or comment below</label>
<textarea [(ngModel)]="contactMessage" class="contact-form-message" id="contactMessage"></textarea>
</div>
<div class="contact-cell">
<label for="interest">What's your comment or question relating to?</label>
<select [(ngModel)]="contactInterest" class="contact-form" id="interest">
<option *ngFor="let interest of boxDataInterest">{{interest['name']}}</option>
</select>
</div>
<div class="contact-cell">
<label for="contactEmail">Finally, what's your email address so we can get back to you?</label>
<input [(ngModel)]="contactEmail" class="contact-form" id="contactEmail" type="text">
</div>
<div class="inline-btn-container">
<div (click)="gdprDisplay()" [ngClass]="{&quot;btn-disabled&quot;: canGDPR()}" class="box-btn box-submit-btn">
<span>NEXT</span>
</div>
</div>
</div>
<!-- /GDPR notice -->
<div [hidden]="!GDPRdisplayStatus" class="contact-container gdpr">
<div class="content-cell">
<span class="contact-title">GDPR Notice</span>
<span class="new-line">We require your permission to receive and manage your data.</span>
<span>For information on how we will process and protect your data, please see our</span>
<a href="https://www.myroyalmail.com/People-Privacy-Notice" target="blank">Privacy Notice.</a>
<div class="gdpr-check">
<input (ngModelChange)="gdprConsent()" [ngModel]="contactGDPR" type="checkbox">
<span>Check the box to confirm your understanding of the GDPR notice.</span>
</div>
<re-captcha (resolved)="resolved($event)" siteKey="6Ld-t9EZAAAAABSTHM9jrKL_WI1_WQ7j-8xXFrqV"></re-captcha>
</div>
<div class="inline-btn-container">
<div (click)="sendEmail()" [ngClass]="{&quot;btn-disabled&quot;: canSubmit()}" class="box-btn box-submit-btn wide-box-btn">
<span>SEND</span>
</div>
<div (click)="gdprDisplay()" class="box-btn box-cancel-btn">
<span>CANCEL</span>
</div>
</div>
</div>
<div class="contact-info">
<div class="contact-info-txt">
<span>Got a question? We will get you an answer!</span>
<br>
Got a suggestion or an idea? We want to hear it?
<br>
Maybe you just want some information?
<br>
We can get it for you!
</div>
<img class="contact-info-img" src="assets/images/RM_Transform_Foamex_Tranform_Logo_630x630mm_v1.0.png">
</div>
</div>
<!-- /Introduction -->
<div [hidden]="boxDataDisplay != &#39;intro&#39;" class="intro">
<span [hidden]="boxDataSubtitle == null" [ngStyle]="{&#39;font-weight&#39;: &#39;bold&#39;}">{{boxDataSubtitle}}</span>
<br>
<span>{{boxDataDescription}}</span>
</div>
<div [hidden]="boxDataDisplay != &#39;document&#39;" class="document-container">
<div (click)="boxDocumentLauncher(sheet)" *ngFor="let sheet of boxDataPDF" class="box-document-btn">
<div [ngStyle]="{&#39;background-image&#39;: &#39;url(&#39; + apiURL + sheet[&#39;thumbnail&#39;][&#39;url&#39;] + &#39;)&#39;}" class="thumbnail"></div>
<span>{{sheet['title']}}</span>
</div>
<span [hidden]="boxDataPDF" class="coming-soon-text">Document coming soon.</span>
</div>
<div [hidden]="boxDataDisplay == &#39;contact&#39;" [ngStyle]="{&#39;background-image&#39;: &#39;url(&#39; + boxDataImage[currentSlideshowIndex] + &#39;)&#39;}" class="image-container-feature-item"></div>
</div>
<!-- /Info box menu -->
<div [hidden]="boxDataDisplay == &#39;contact&#39;" class="btn-container">
<div (click)="boxContentDisplayCtrl(&quot;intro&quot;)" [ngClass]="{&#39;btn-actived&#39;: boxDataDisplay == &#39;intro&#39;}" class="btn btn-bg0">
<i class="fas fa-info"></i>
</div>
<div (click)="boxContentDisplayCtrl(&quot;video&quot;)" [ngClass]="{&#39;btn-actived&#39;: boxDataDisplay == &#39;video&#39;}" class="btn btn-bg1">
<i class="fas fa-film"></i>
</div>
<div (click)="boxContentDisplayCtrl(&quot;document&quot;)" [ngClass]="{&#39;btn-actived&#39;: boxDataDisplay == &#39;document&#39;}" class="btn btn-bg2">
<i class="fas fa-file-alt"></i>
</div>
<div (click)="boxContentDisplayCtrl(&quot;contact&quot;)" [ngClass]="{&#39;btn-actived&#39;: boxDataDisplay == &#39;contact&#39;}" class="btn btn-bg3">
<i class="far fa-envelope"></i>
</div>
</div>
</div>
</div>
</div>
<!-- /Mobile swipe reminder -->
<div [hidden]="!showTutorial" class="mobile-tutorial-container">
<svg data-name="Swipe horizontal 1" id="Swipe-horizontal_1" viewbox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
<path class="hand-x" d="M139.93,113.56l-41.12-6.93V76.08a9.25,9.25,0,0,0-9.25-9.25h0a9.25,9.25,0,0,0-9.25,9.25v57.36L71,122.65c-3.61-3.61-8.44-3.89-13.08,0,0,0-7.24,5.84-3.83,9.25l34,34h42.63a9.25,9.25,0,0,0,9.07-7.43l6.82-34.09A9.28,9.28,0,0,0,139.93,113.56Z"></path>
<g class="swipe-horizontal">
<path class="line-horizontal" d="M70.85,42c19.69-12.46,37,0,37,0"></path>
<polyline class="arrow-left" points="76.6 46.01 68.37 43.43 68.38 43.41 70.96 35.18"></polyline>
<polyline class="arrow-right" points="100.21 44.66 108.43 42.08 108.43 42.06 105.85 33.84"></polyline>
</g>
</svg>
<span class="v2-type-sm-b tutorial-hint">Swipe Left / Right to view the map</span>
<div (click)="showTutorial = false" class="tutorial-btn hyper-link hover-reaction disable-select">
<span>Got it</span>
</div>
</div>

import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-hotspot',
  templateUrl: './hotspot.component.html',
  styleUrls: ['./hotspot.component.scss']
})
export class HotspotComponent implements OnInit {
  constructor(private ApiService: ApiService) { }
  @Input() dataIn: object;

  pinTitle:string    = null;
  pinNumber:string   = null;

  ngOnInit(): void {
    this.initPin();
  }

  initPin(): void {
    this.pinTitle = this.dataIn['title'];
    if(this.dataIn['hotspotAlias']){
      this.pinNumber = this.dataIn['hotspotAlias'];
    }
  }

}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  apiUrl: string = "https://api.elasticemail.com/v2/email/send"

  constructor(private http: HttpClient) { }

  /*   async sendEmail(contactFullName:string, contactMessage: string, contactEmail:string, contactInterest:string, contactJob:string) {
      // let emailData = JSON.stringify({"contactFullName":contactFullName, "contactMessage": contactMessage, "contactEmail":contactEmail, "contactInterest":contactInterest, "contactJob":contactJob});
      let emailData = {"contactFullName":contactFullName, "contactMessage": contactMessage, "contactEmail":contactEmail, "contactInterest":contactInterest, "contactJob":contactJob};
      const { data } = await axios.post('https://thisisus.royalmail.com/mailer/royalmail.php', emailData);
      return data;
    } */

  async sendEmail(contactFullName: string, contactMessage: string, contactEmail: string, contactInterest: string, contactJob: string) {
    const body = await this.getEmailBody({
      contactFullName, contactEmail, contactJob, contactMessage, contactInterest
    });

    const params = new HttpParams()
      .set('apikey', environment.emailApiKey)
      .set('to', 'theacademy@royalmail.com')
      .set('from', "noreply@mail.the-podium.com")
      .set('subject', 'New message from Podium ThisIsUs.Royalmail.com')
      .set('bodyHtml', body);

    return this.http.post(this.apiUrl, params).toPromise()
  }

  async getEmailBody(contactInfo) {
    const {
      contactFullName,
      contactEmail,
      contactJob,
      contactMessage,
      contactInterest
    } = contactInfo;

    return "Message sent from ThisIsUs.Royalmail.com" + "<br/>" +
      "Name: " + contactFullName + "<br/>" +
      "Email: " + contactEmail + "<br/>" +
      "Function: " + contactJob + "<br/>" +
      "Related to: " + contactInterest + "</p>" +
      "Question/Message: " + contactMessage + "</p>" +
      "<p><i>This is an automated email. Please do not reply this email.</i></p>";
  }
}

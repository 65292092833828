import { Component, AfterContentInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements AfterContentInit{
  hideSpinner = false;

  ngAfterContentInit() {
    // this.hideSpinner = true;
    setTimeout (() => {
      this.hideSpinner = true;
    }, 2500);
  }
}

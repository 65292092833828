import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { MailService } from '../mail.service';
import Player from "@vimeo/player";


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  //contactFirstName      = null;
  //contactSurname        = null;
  contactFullName       = null;
  contactEmail          = null;
  //contactCompany        = null;
  contactMessage        = null;
  //contactPhone          = null;
  contactInterest       = null;
  contactJob            = null;
  contactGDPR           = false;


  recaptchaVerified     = false;

  mapData               = null;
  mapBg                 = null;
  mapVid                = null;
  mapPlayer             = null;
  popupPlayer           = null;
  hotspots              = null;
  hotspotFilter         = [];
  boxData               = null;
  boxDataTitle          = null;
  boxDataDescription    = null;
  boxDataSubtitle       = null;
  boxDataPDF            = null;
  boxDataInterest       = [];
  boxDataJobs           = [];
  boxDataImage          = [];
  boxDataDisplay        = 'intro';
  boxDataVideo          = null;
  boxDataVideoURL       = null;
  currentSlideshowIndex = 0;

  slideshowLength       = 0;

  boxDisplay            = false;
  boxLoaderDisplay      = false;
  boxContentDisplay     = false;
  mobileMenuDisplay     = false;
  mobileReminderDisplay = false;
  GDPRdisplayStatus     = false;
  showTutorial          = true;
  apiURL                = this.ApiService.getAPIURL();

  filterBulk            = true;
  filterPals            = true;
  filterTritec          = true;
  filterNorthern        = true;

  logo                  = null;

  popupStory            = null;
  popupStoryPlay        = false;
  popupStoryURL         = null;

  popupVideo            = null;
  popupVideoPlay        = false;
  popupVideoURL         = null;


  constructor(private MailService: MailService, private ApiService: ApiService) { }

  ngOnInit(): void {
    this.initMap();
  }

  initMap(): void {
    this.ApiService.getMapWithID('1').subscribe((response) => {
        this.mapData = response;
        this.boxDataInterest = this.mapData['interest'];
        this.boxDataJobs = this.mapData['job'];
        this.mapBg   = this.apiURL + this.mapData['mapBackgroundImage']['url'];
        this.mapVid  = this.mapData['mapVideoURL'] + '?background=1';
        this.initHotspots(this.mapData['hotspot']);
        this.initPopups(this.mapData['Video']);
        this.logo = this.apiURL + this.mapData['logoImage']['url'];

        this.initMapVideo();
    });
  }

  initMapVideo(): void {
    let options = {
      url: this.mapVid,
      background: true,
      responsive: true,
      autopause: false
    };
    this.mapPlayer = new Player('map-vid', options);
  }

  initHotspots(arrayIn:object): void {
    this.hotspots = arrayIn;
  }

  //Initiates the popup story and video
  initPopups(arrayIn: object): void{
    this.popupStory = arrayIn[0];
    this.popupVideo = arrayIn[1];
    this.initStoryVideo();
  }

  initStoryVideo(): void {
    let options = {
      url: this.popupStory['videoURL'],
      responsive: true,
      loop: true,
      title: false,
      byline: false
    };

    this.popupPlayer = new Player('popup-vid', options);
  }

  contactCtrl(): void {
    this.boxDataDisplay    = 'contact';
    this.boxDisplay         = true;
    this.boxContentDisplay  = true;
  }

  boxDisplayCtrl(arrayIn:object): void {
    //Diect PDF file display
    if(arrayIn && arrayIn['type'] === 'directPDF') {
      let pdfURL = this.apiURL + arrayIn['PDF'][0]['file'][0]['url'];
      window.open(pdfURL, '_blank');
    } else {
      //Normal Drilldown Display Setup / Close
      this.boxDisplayCtrlSetup(arrayIn);
    }
  }

  boxDisplayCtrlSetup(arrayIn:object):void {
    if(arrayIn) {
      this.boxData              = arrayIn;
      this.boxDataTitle         = this.boxData['title'];
      this.boxDataDescription   = this.boxData['description'];
      this.boxDataSubtitle      = this.boxData['subtitle']  ? this.boxData['subtitle'] : null;
      this.boxDataPDF           = this.boxData['PDF'];
      this.boxDataVideo         = this.boxData['Video'];

      //Reset empty PDF array
      if(this.boxDataPDF.length == 0) {
        this.boxDataPDF = null;
      }

      this.boxDataVideoURL = null;

      if(this.boxData['assetImage'].length > 0) {
        for(let image of this.boxData['assetImage']){
          this.boxDataImage.push(this.apiURL + image['url']);
        }
        this.slideshowLength = this.boxDataImage.length;
      } else {
        this.boxDataImage = ['./../assets/images/na-image.jpg'];
      }
    }

    this.boxDisplay = !this.boxDisplay;

    if(this.boxDisplay) {
      this.boxLoaderDisplay = true;
      setTimeout (() => {
        this.boxLoaderDisplay   = false;
        this.boxContentDisplay  = true;
      }, 1000);
    } else {
      this.boxData               = null;
      this.boxContentDisplay     = false;
      this.boxDataDisplay        = 'intro';
      this.boxDataVideoURL       = null;
      this.boxDataImage          = [];
      this.contactEmail          = null;
      this.GDPRdisplayStatus     = false;
      //this.contactFirstName      = null;
      //this.contactSurname        = null;
      this.contactFullName       = null;
      this.contactEmail          = null;
      //this.contactCompany        = null;
      this.contactMessage        = null;
      //this.contactPhone          = null;
      this.contactInterest       = [false,false,false,false];
    }
  }

  hotspotDisplay(arrayIn:object): boolean {

    let emptyArray = true;
    this.hotspotFilter.forEach(function(element) {
      if(element) {
        emptyArray = false;
      }
    })

    if(emptyArray) {
      return false;
    } else {
      if(arrayIn['filters'].indexOf(this.hotspotFilter[0]) >= 0) {
        //return false;
        return true;
      } else if(arrayIn['filters'].indexOf(this.hotspotFilter[1]) >= 0) {
        //return false;
        return true;
      } else if(arrayIn['filters'].indexOf(this.hotspotFilter[2]) >= 0) {
        //return false;
        return true;
      } else if(arrayIn['filters'].indexOf(this.hotspotFilter[3]) >= 0) {
        //return false;
        return true;
      } else {
        //return true;
        return false;
      }
    }
  }

  //Displaying the greyed filter
  filterDisplay(nameIn:string, indexIn:number): boolean {
    if(this.hotspotFilter[indexIn] === nameIn) {
      return true;
    } else {
      return false;
    }
  }

  //Puts the filter into the array
  filterHandler(filterIn:string, indexIn:number): void {
    if(!this.hotspotFilter[indexIn]) {
      this.hotspotFilter[indexIn] = filterIn;
    } else {
      this.hotspotFilter[indexIn] = null;
    }
  }

  innerLogoDisplay(filterIn:string): boolean {
    if(this.boxData) {
      if(this.boxData['filters']){
        if (this.boxData['filters'].indexOf(filterIn) >= 0) {
          return false
        } else {
          return true
        }
      }else{
        return true;
      }
    } else {
      return true
    }
  }

  boxContentDisplayCtrl(sectionIn:string): void {
    if(this.boxDataDisplay == sectionIn){
      this.boxDataDisplay = null;
      this.boxDataVideoURL = null;
    }else{
      this.boxDataDisplay = sectionIn;
      if(sectionIn == 'video'){
        if(this.boxDataVideo.length >= 0){
          this.boxDataVideoURL = this.boxDataVideo[0]['videoURL'];
        }
      }else{
        this.boxDataVideoURL = null;
      }
    }
  }

  boxDocumentLauncher(arrayIn:string): void {
    window.open(this.apiURL + arrayIn['file'][0]['url'],'_blank');
  }

  gdprDisplay(): void {
    this.GDPRdisplayStatus = !this.GDPRdisplayStatus;
  }

  contactFormHandler(): void {

  }

  gdprConsent(): void {
    this.contactGDPR = !this.contactGDPR;
  }


  //For the recaptcha
  resolved(capchtaResponse: string): void{
    this.recaptchaVerified = !this.recaptchaVerified;
  }

  canGDPR(): boolean{
    if(this.contactFullName && this.contactInterest && this.contactEmail && this.contactJob && this.contactMessage){
      return false;
    }
    return true;
  }

  canSubmit(): boolean{
    if(this.contactGDPR && this.recaptchaVerified){
      return false;
    }
    return true;
  }

  async sendEmail(){
    await this.MailService.sendEmail(this.contactFullName,this.contactMessage,this.contactEmail,this.contactInterest,this.contactJob).then((res: any) => {
      console.log(res);
      if(res.success){
        window.alert("Your message has been sent. Thank you!");
        this.clearContactForm();
        this.boxDisplayCtrl(null);
      }else{
        window.alert("Something went wrong. Please try again.");
      }
    });
  }

  clearContactForm(): void {
    this.contactFullName = null;
    this.contactMessage = null;
    this.contactEmail = null;
    this.contactInterest = null;
    this.contactJob = null;
    this.contactGDPR = null;
  }

  //Turns the slideshow by the amount of the number passed in.
  turnSlideshow (direction: number): void{
    this.currentSlideshowIndex += direction;
    if(this.currentSlideshowIndex  < 0){
      this.currentSlideshowIndex = (this.boxDataImage.length - 1);
    }else if (this.currentSlideshowIndex > (this.boxDataImage.length - 1)){
      this.currentSlideshowIndex = 0;
    }
  }

  //Plays the fullscreen story
  playStory(): void{

    this.popupStoryPlay = !this.popupStoryPlay;

    if(this.popupStoryPlay){
      // this.popupStoryURL = this.popupStory['videoURL'];
      this.popupPlayer.setCurrentTime(0);
      this.popupPlayer.play();
      /*
      //Puts into fullscreen
      let elem = document.documentElement;
      let methodToBeInvoked = elem.requestFullscreen ||
       elem['mozRequestFullscreen'] || elem['msRequestFullscreen'];
      if (methodToBeInvoked) {
        console.log("here");
        methodToBeInvoked.call(elem)
      }
      */
    }else{
      this.popupStoryURL = null;
      this.popupPlayer.pause();
      /*
      let methodToBeInvoked = document.exitFullscreen() ||
      document['mozCancelFullScreen'] || document['msExitFullscreen'];
      */
    }
  }

  playPopupVideo(): void{
    this.popupVideoPlay = !this.popupVideoPlay;
    if(this.popupVideoPlay){
      this.popupVideoURL = this.popupVideo['videoURL'];
    }else{
      this.popupVideoURL = null;
    }
  }

  playVideo(videoURL: string): void{
    // console.log(videoURL);
    this.boxDataVideoURL = videoURL;
  }
}

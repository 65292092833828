import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }
  token: string = '4adc33e1f9b765b4eeb03525a75b9a';
  apiURL: string = 'https://aukl-vie-demo.the-podium.com';
  collectionPath: string = '/podium-royal-mails' ;
  authPath: string = '/api/cockpit/authUser';
  assetPath: string = '/api/cockpit/assets';


  getAPIURL() {
    return this.apiURL;
  }

  getVideo(message: string){
    return message + '.mp4';
  }

  getMapPin(){
    let pinApiURL = this.apiURL + this.collectionPath;
    let obs = this.http.get(pinApiURL);
    return obs;
  }

  getMapWithID(ID:string){
    let pinApiURL = this.apiURL + this.collectionPath + '/' + ID;
    let obs = this.http.get(pinApiURL);
    return obs;
  }

  getDrilldown(){
    let pinApiURL = this.apiURL + this.collectionPath +  'Drilldown/?token=' + this.token;
    let obs = this.http.get(pinApiURL);
    return obs;
  }

  getContentItems() {
    let pinApiURL = this.apiURL + this.collectionPath +  'ContentItems/?token=' + this.token;
    let obs = this.http.get(pinApiURL);
    return obs;
  }

  getAsset(){
    let pinApiURL = this.apiURL + this.assetPath + '?token=' + this.token;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };

    let obs = this.http.get(pinApiURL);
    return obs;
  }

  getPoster(StringIn: string) {
    let URL = this.apiURL + '/' + StringIn;
    return URL.toString();
  }

  getPDF(StringIn: string) {
    let URL = this.apiURL + '/' + StringIn;
    return URL.toString();
  }

  getAuth() {
    fetch(this.apiURL + this.authPath + '?token=' + this.token, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            user: 'cfok',
            password: 'cfok'
        })
    })
    .then(user => user.json())
    .then(user => console.log(user));
  }
}
